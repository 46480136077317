import bdLogger from '@/utils/bdLogger';
import mergeCompsetData from '@/utils/mergeCompsetData';
import useRequestCache from '@/utils/useRequestCache';
import generateFetchUrl from '@/utils/generateFetchUrl';
import logout from '@/utils/logout';

import useSectionState from '@/state/useSectionState';
import useMainState from '@/state/useMainState';

const transformToCompsetData = (data) => {
  if (typeof data !== 'object' || !Object.keys(data).length) {
    return data;
  }

  return Object.keys(data).reduce((acc, key) => {
    acc[key] = (data[key] || []).map(mergeCompsetData).filter(Boolean);
    return acc;
  }, {});
};

// Url and params config
const fetchRequest = async (pipe, requestConfig = {}) => {
  const {
    isCtpCall,
    isScoreCall,
  } = requestConfig;

  const {
    runningDataCalls,
    runningScoreCalls,
    recentCalls,
  } = useSectionState();

  const {
    isOverview,
    propertyId,
  } = useMainState();

  const { useFetchCache } = useRequestCache();

  const url = generateFetchUrl(pipe, requestConfig);

  const existingRecentCall = recentCalls.value.filter((call) => call.url === url)[0];

  let callPrefixId;

  if (isScoreCall) {
    callPrefixId = 'SCORE';
  } else {
    callPrefixId = isCtpCall ? 'CTP' : 'VD';
  }
  callPrefixId += `(${isOverview.value ? 'Overview' : propertyId.value})`;

  const logData = `[${pipe}] ${callPrefixId}`;

  if (existingRecentCall) {
    bdLogger.log(`Retrieving saved data: ${logData}`);
    return {
      data: isScoreCall
        ? existingRecentCall.data
        : transformToCompsetData(existingRecentCall.data),
      meta: existingRecentCall.meta,
    };
  }

  bdLogger.log(`Fetching data: ${logData}
URL: ${url}`);

  const { signal } = isScoreCall ? runningScoreCalls.value : runningDataCalls.value;

  const fetchOptions = {
    url,
    signal,
    method: 'GET',
  };

  const res = await useFetchCache(fetchOptions);

  const { data, meta } = res.data;

  const debugText = `Received response from ${logData}:
    - Response status: ${res.status}`;

  bdLogger.saveDebug(debugText);

  switch (res.status) {
    case 401:
      // unauthorized - unauthenticated
      logout();
      break;
    case 400:
      // bad request (invalid dates vs dashboard_date)
      return { data };
    case 200:
      if (Array.isArray(data) && data.length) {
        if (isScoreCall) {
          const scoreData = data.map((i) => ({
            ...i,
            pipe,
          }));

          recentCalls.value.push({
            url,
            data: scoreData,
          });

          return { data: scoreData };
        }

        break;
      }

      recentCalls.value.push({
        url,
        data,
        meta,
      });

      break;

    default:
      throw new Error(`Failed to fetch data from ${url}: STATUS ${res.status}`);
  }

  return {
    data: transformToCompsetData(data),
    meta,
  };
};

export default fetchRequest;
